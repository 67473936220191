import { React, useState, useRef } from 'react';
import Select from 'react-select';
import { sendApplication } from '../../api/EmailService';
import Input from '../UI/Input';
import validate from '../../utils/Validate';
import ErrorIcon from '../../images/warning.svg';

const ApplicationForm = (props) => {
  const [applicationForm, setapplicationForm] = useState({
    formControls: {
      firstName: {
        value: '',
        ref: useRef(null),
        validation: {
          valid: true,
          errorMessage: '',
        },
        validationRules: {
          isRequired: true,
        },
      },
      lastName: {
        value: '',
        ref: useRef(null),
        validation: {
          valid: true,
          errorMessage: '',
        },
        validationRules: {
          isRequired: true,
        },
      },
      age: {
        value: '',
        ref: useRef(null),
        validation: {
          valid: true,
          errorMessage: '',
        },
        validationRules: {
          isRequired: true,
          onlyNumbersAndDecimalNumbers: true,
          ageRule: true,
        },
      },
      email: {
        value: '',
        ref: useRef(null),
        validation: {
          valid: true,
          errorMessage: '',
        },
        validationRules: {
          isRequired: true,
          isEmail: true,
        },
      },
      height: {
        value: '',
        ref: useRef(null),
        validation: {
          valid: true,
          errorMessage: '',
        },
        validationRules: {
          isRequired: true,
          onlyNumbersAndDecimalNumbers: true,
          heightRule: true, 
        },
      },
      weight: {
        value: '',
        ref: useRef(null),
        validation: {
          valid: true,
          errorMessage: '',
        },
        validationRules: {
          isRequired: true,
          onlyNumbersAndDecimalNumbers: true,
          weightRule: true,
        },
      },
      questions: [
        {
          question: 'What\'s your favorite book?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {},
        },
        {
          question: 'What type of coaching do you apply for?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {
            isRequired: true,
          },
        },
        {
          question: 'What is your training experience?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {
            isRequired: true,
          },
        },
        {
          question: 'Which option best describes you right now?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {
            isRequired: true,
          },
        },
        {
          question: 
            'In your ideal life, how would you look and feel? Really think about this. '
            + 'What would a change mean for your life? For those around you? How would'
            + ' your life be different? ',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {
            isRequired: true,
          },
        },
        {
          question: 'What do think your biggest obstacle is to achieving that life?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {
            isRequired: true,
          },
        },
        {
          question: 'Have you ever worked with a wellness or fitness coach before?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {
            isRequired: true,
          },
        },
        {
          question: 'If yes, what did you love about that experience? What were some of your frustrations?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {},
        },
        {
          question: 'Any injuries or health problems I should know about?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {
            isRequired: true,
          },
        },
        {
          question: 
            'On a scale of 1 to 10, 1 being "I just want to sit on the couch and eat pizza"'
            + 'and 10 being "I\'ll eat blended chicken and broccoli all day, everyday if that\'s'
            + 'what it takes", how committed are you to achieving your fitness goals?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {
            isRequired: true,
          },
        },
        {
          question: 'Are you ready to invest in your self, and achieve your goals?',
          answer: '',
          ref: useRef(null),
          validation: {
            valid: true,
            errorMessage: '',
          },
          validationRules: {},
        },

      ],
    },
  });

  const options = [
    { 
      value: 'I want to loose weight. The fastest, the better', 
      label: 'I want to loose weight. The fastest, the better', 
    },
    { 
      value: 'I want to be healthy and fit. The scale doesn\'t matter', 
      label: 'I want to be healthy and fit. The scale doesn\'t matter', 
    },
    { 
      value: 'I want to be strong. Bring on the weights', 
      label: 'I want to be strong. Bring on the weights', 
    },
    { 
      value: 'I want to go the distance. I am training for a race!', 
      label: 'I want to go the distance. I am training for a race!', 
    },
  ];

  const options1 = [
    { 
      value: 'In Person', 
      label: 'In Person',
    },
    { 
      value: 'Online', 
      label: 'Online', 
    },
    { 
      value: 'I am not sure', 
      label: 'I am not sure', 
    },
  ];

  const changeHandler = (event, actionMeta) => {
    const { name } = event.target ? event.target : actionMeta;
    let value;

    if (event.value) {
      value = event.value;
    } else {
      value = event.target.value;
    } 
    
    const updatedControls = {
      ...applicationForm.formControls,
    };

    if (updatedControls[name]) {
      updatedControls[name].value = value;
    } else {
      updatedControls.questions[name].answer = value;
    }
     
    setapplicationForm({
      formControls: updatedControls,
    });
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();

    const updatedControls = {
      ...applicationForm.formControls,
    };

    let formIsValid = true;
    for (const formElementId in updatedControls) {
      if (updatedControls[formElementId].validation) {
        updatedControls[formElementId].validation = validate(
          updatedControls[formElementId].value,
          updatedControls[formElementId].validationRules,
        );
    
        if (!updatedControls[formElementId].validation.valid && formIsValid) {
          updatedControls[formElementId].ref.current.focus();
        }
        formIsValid = updatedControls[formElementId].validation.valid && formIsValid;
      } else {
        for (const formQuestions in updatedControls.questions) {
          if (Object.prototype.hasOwnProperty.call(updatedControls, formElementId)) {
            updatedControls.questions[formQuestions].validation = validate(
              updatedControls.questions[formQuestions].answer,
              updatedControls.questions[formQuestions].validationRules,
            );
            if (!updatedControls.questions[formQuestions].validation.valid && formIsValid) {
              updatedControls.questions[formQuestions].ref.current.focus();
            }

            formIsValid = updatedControls.questions[formQuestions].validation.valid && formIsValid;
          }
        }
      }
    }

    if (formIsValid) {
      const formData = {};
      const questions = [];

      for (const formElementId in updatedControls) {
        if (updatedControls[formElementId].value) {
          formData[formElementId] = updatedControls[formElementId].value;
        } else {
          for (const formQuestions in updatedControls.questions) {
            if (Object.prototype.hasOwnProperty.call(updatedControls, formElementId)) {
              questions[formQuestions] = { 
                question: updatedControls.questions[formQuestions].question,
                answer: updatedControls.questions[formQuestions].answer, 
              };
            }
          }
          formData[formElementId] = questions;
        }
      }

      props.loadHandler(true);
      sendApplication(formData).then(() => {
        for (const formElementId in updatedControls) {
          if (Object.prototype.hasOwnProperty.call(updatedControls, formElementId)) {
            if (updatedControls[formElementId].value) {
              updatedControls[formElementId].value = '';
            } else {
              for (const formQuestions in updatedControls.questions) {
                if (Object.prototype.hasOwnProperty.call(updatedControls, formElementId)) {
                  if (formQuestions !== 1 || formQuestions !== 3) {
                    updatedControls.questions[formQuestions].answer = '';
                  } else {
                    updatedControls.questions[formQuestions].answer = { value: '', label: '' };
                  }
                }
              }
            }
          }
        }

        props.modalHandler(true);
        props.loadHandler(false);

        setapplicationForm({
          formControls: updatedControls,
        });
      }).catch(() => {
        props.modalHandler(false);
        props.loadHandler(false);
      });
    } else {
      setapplicationForm({
        formControls: updatedControls,
      });
    }
  };

  return (
    <div className="application-form">
      <form className="form" onSubmit={formSubmitHandler}>

        <div className="form-group">
          <label className="label" htmlFor="firstName">
            Name*
          </label>

          <div className="double-input">
            <input
              className="input"
              type="text"
              name="firstName"
              ref={applicationForm.formControls.firstName.ref}
              value={applicationForm.formControls.firstName.value}
              onChange={changeHandler}
              valid={applicationForm.formControls.firstName.validation.valid}
              errorMessage={applicationForm.formControls.firstName.validation.errorMessage}
            />

            <input
              className="input"
              type="text"
              name="lastName"
              ref={applicationForm.formControls.lastName.ref}
              value={applicationForm.formControls.lastName.value}
              onChange={changeHandler}
              valid={applicationForm.formControls.lastName.validation.valid}
              errorMessage={applicationForm.formControls.lastName.validation.errorMessage}
            />

            <p className="field-name">First Name</p>
            <p className="field-name">Last Name</p>
            {!applicationForm.formControls.firstName.validation.valid 
              ? (
                <div className="error">
                  <p className="error-message">
                    <img className="error-icon" src={ErrorIcon} alt="error" />
                    {applicationForm.formControls.firstName.validation.errorMessage}
                  </p>
                </div>
              ) 
              : null}
            {!applicationForm.formControls.lastName.validation.valid 
              ? (
                <div className="error">
                  <p className="error-message">
                    <img className="error-icon" src={ErrorIcon} alt="error" />
                    {applicationForm.formControls.lastName.validation.errorMessage}
                  </p>
                </div>
              ) 
              : null}
          </div>
        </div>

        <Input
          name="age"
          className="input"
          label="Age*"
          ref={applicationForm.formControls.age.ref}
          labelStyle="label"
          value={applicationForm.formControls.age.value}
          onChange={changeHandler}
          valid={applicationForm.formControls.age.validation.valid}
          errorMessage={applicationForm.formControls.age.validation.errorMessage}
        />

        <div className="form-group">
          <label className="label" htmlFor="email">
            Email*
          </label>

          <input
            className="input"
            type="email"
            name="email"
            ref={applicationForm.formControls.email.ref}
            value={applicationForm.formControls.email.value}
            onChange={changeHandler}
            valid={applicationForm.formControls.email.validation.valid}
          />

          <p className="field-name">example@example.com</p>
          {!applicationForm.formControls.email.validation.valid 
            ? (
              <div className="error">
                <p className="error-message">
                  <img className="error-icon" src={ErrorIcon} alt="error" />
                  {applicationForm.formControls.email.validation.errorMessage}
                </p>
              </div>
            ) 
            : null}
        </div>

        <div className="form-group">

          <div className="double-input">
            <label className="label" htmlFor="weight">
              Weight*
            </label>
            <label className="label" htmlFor="height">
              Height*
            </label>

            <input
              className="input"
              type="text"
              name="weight"
              ref={applicationForm.formControls.weight.ref}
              value={applicationForm.formControls.weight.value}
              onChange={changeHandler}
              valid={applicationForm.formControls.weight.validation.valid}
              errorMessage={applicationForm.formControls.weight.validation.errorMessage}
            />

            <input
              className="input"
              type="text"
              name="height"
              ref={applicationForm.formControls.height.ref}
              value={applicationForm.formControls.height.value}
              onChange={changeHandler}
              valid={applicationForm.formControls.height.validation.valid}
              errorMessage={applicationForm.formControls.height.validation.errorMessage}
            />

            {!applicationForm.formControls.weight.validation.valid 
              ? (
                <div className="error">
                  <p className="error-message">
                    <img className="error-icon" src={ErrorIcon} alt="error" />
                    {applicationForm.formControls.weight.validation.errorMessage}
                  </p>
                </div>
              ) 
              : null}
            {!applicationForm.formControls.height.validation.valid 
              ? (
                <div className="error">
                  <p className="error-message">
                    <img className="error-icon" src={ErrorIcon} alt="error" />
                    {applicationForm.formControls.height.validation.errorMessage}
                  </p>
                </div>
              ) 
              : null}
          </div>
        </div>

        <Input
          name="0"
          className="input"
          label="What's your favorite book?"
          labelStyle="label"
          value={applicationForm.formControls.questions[0].answer}
          onChange={changeHandler}
          valid={applicationForm.formControls.questions[0].validation.valid}
          errorMessage={applicationForm.formControls.questions[0].validation.errorMessage}
        />

        <div className="form-group">
          <label className="label">
            What type of coaching do you apply for?*
          </label>
          <Select 
            className="select-input"
            onChange={changeHandler}
            value={{
              value: applicationForm.formControls.questions[1].answer,
              label: applicationForm.formControls.questions[1].answer,
            }}
            ref={applicationForm.formControls.questions[1].ref}
            isClearable={true}
            isSearchable={true}
            name="1"
            options={options1} 
          />
          {!applicationForm.formControls.questions[1].validation.valid 
            ? (
              <div className="error">
                <p className="error-message">
                  <img className="error-icon" src={ErrorIcon} alt="error" />
                  {applicationForm.formControls.questions[1].validation.errorMessage}
                </p>
              </div>
            ) 
            : null}
        </div>
        
        <div className="form-group">
          <label className="label" htmlFor="legend">What is your training experience?*</label>
          <div className="radio-group">
            <label className="radio__container">
              <input
                type="radio"
                value="Less than 1 year"
                name="2"
                checked={applicationForm.formControls.questions[2].answer === 'Less than 1 year'}
                ref={applicationForm.formControls.questions[2].ref}
                onChange={changeHandler}
              />
              <span className="checkmark" />
              Less than 1 year
            </label>
            <label className="radio__container">
              <input
                type="radio"
                value="1 - 2 years"
                name="2"
                checked={applicationForm.formControls.questions[2].answer === '1 - 2 years'}
                ref={applicationForm.formControls.questions[2].ref}
                onChange={changeHandler}
              />
              <span className="checkmark" />
              1 - 2 years
            </label>
            <label className="radio__container">
              <input
                type="radio"
                value="3 - 4 years"
                name="2"
                checked={applicationForm.formControls.questions[2].answer === '3 - 4 years'}
                ref={applicationForm.formControls.questions[2].ref}
                onChange={changeHandler}
              />
              <span className="checkmark" />
              3 - 4 years
            </label>
            <label className="radio__container">
              <input
                type="radio"
                value="4+ years"
                name="2"
                checked={applicationForm.formControls.questions[2].answer === '4+ years'}
                onChange={changeHandler}
              />
              <span className="checkmark" />
              4+ years
            </label>
          </div>
          {!applicationForm.formControls.questions[2].validation.valid 
            ? (
              <div className="error">
                <p className="error-message">
                  <img className="error-icon" src={ErrorIcon} alt="error" />
                  {applicationForm.formControls.questions[2].validation.errorMessage}
                </p>
              </div>
            ) 
            : null}
        </div>

        <div className="form-group">
          <label className="label">
            Which option best describes you right now?*
          </label>
          <div className="select__container">
            <Select 
              value={{
                value: applicationForm.formControls.questions[3].answer,
                label: applicationForm.formControls.questions[3].answer,
              }}
              ref={applicationForm.formControls.questions[3].ref}
              className="select-input"
              onChange={changeHandler}
              isClearable={true}
              isSearchable={true}
              name="3"
              options={options}
            />
          </div>
          {!applicationForm.formControls.questions[3].validation.valid 
            ? (
              <div className="error">
                <p className="error-message">
                  <img className="error-icon" src={ErrorIcon} alt="error" />
                  {applicationForm.formControls.questions[3].validation.errorMessage}
                </p>
              </div>
            ) 
            : null}
        </div>

        <div className="form-group">
          <label className="label" htmlFor="question4">
            In your ideal life, how would you look and feel?*
          </label>
          <div className="sub-label">
            Really think about this. What would a change mean for 
            your life? For those around you? How would your life be 
            different?
          </div>

          <input
            className="input"
            type="text"
            name="4"
            value={applicationForm.formControls.questions[4].answer}
            ref={applicationForm.formControls.questions[4].ref}
            onChange={changeHandler}
            valid={applicationForm.formControls.questions[4].validation.valid}
            errorMessage={applicationForm.formControls.questions[4].validation.errorMessage}
          />

          {!applicationForm.formControls.questions[4].validation.valid 
            ? (
              <div className="error">
                <p className="error-message">
                  <img className="error-icon" src={ErrorIcon} alt="error" />
                  {applicationForm.formControls.questions[4].validation.errorMessage}
                </p>
              </div>
            ) 
            : null}
        </div>

        <Input
          name="5"
          className="input"
          label="What do think your biggest obstacle is to achieving that life?*"
          labelStyle="label"
          value={applicationForm.formControls.questions[5].answer}
          ref={applicationForm.formControls.questions[5].ref}
          onChange={changeHandler}
          valid={applicationForm.formControls.questions[5].validation.valid}
          errorMessage={applicationForm.formControls.questions[5].validation.errorMessage}
        />

        <div className="form-group">
          <label className="label" htmlFor="legend">Have you ever worked with a wellness or fitness coach before?*</label>
          <div className="radio-group">
            <label className="radio__container">
              <input
                type="radio"
                value="Yes"
                name="6"
                checked={applicationForm.formControls.questions[6].answer === 'Yes'}
                ref={applicationForm.formControls.questions[6].ref}
                onChange={changeHandler}
              />
              <span className="checkmark" />
              Yes
            </label>
            <label className="radio__container">
              <input
                type="radio"
                value="No"
                name="6"
                checked={applicationForm.formControls.questions[6].answer === 'No'}
                ref={applicationForm.formControls.questions[6].ref}
                onChange={changeHandler}
              />
              <span className="checkmark" />
              No
            </label>
          </div>
          {!applicationForm.formControls.questions[6].validation.valid 
            ? (
              <div className="error">
                <p className="error-message">
                  <img className="error-icon" src={ErrorIcon} alt="error" />
                  {applicationForm.formControls.questions[6].validation.errorMessage}
                </p>
              </div>
            ) 
            : null}
        </div>

        <Input
          name="7"
          className="input"
          label="If yes, what did you love about that experience?
          What were some of your frustrations?"
          labelStyle="label"
          value={applicationForm.formControls.questions[7].answer}
          onChange={changeHandler}
          valid={applicationForm.formControls.questions[7].validation.valid}
          errorMessage={applicationForm.formControls.questions[7].validation.errorMessage}
        />

        <Input
          name="8"
          className="input"
          label="Any injuries or health problems I should know about?*"
          labelStyle="label"
          value={applicationForm.formControls.questions[8].answer}
          ref={applicationForm.formControls.questions[8].ref}
          onChange={changeHandler}
          valid={applicationForm.formControls.questions[8].validation.valid}
          errorMessage={applicationForm.formControls.questions[8].validation.errorMessage}
        />

        <div className="form-group">
          <label className="label" htmlFor="legend">
            On a scale of 1 to 10, 1 being &quot;I just want to sit on 
            the couch and eat pizza&quot;
            and 10 being &quot;I&apos;ll eat blended chicken and broccoli all
            day, everyday if that&apos;s what it takes&quot;, how committed are you to 
            achieving your fitness goals?*
          </label>
          <div className=" radio-group radio-group--inline">
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">1</span>
              <input
                type="radio"
                value="1"
                ref={applicationForm.formControls.questions[9].ref}
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '1'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">2</span>
              <input
                type="radio"
                value="2"
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '2'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">3</span>
              <input
                type="radio"
                value="3"
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '3'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">4</span>
              <input
                type="radio"
                value="4"
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '4'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">5</span>
              <input
                type="radio"
                value="5"
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '5'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">6</span>
              <input
                type="radio"
                value="6"
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '6'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">7</span>
              <input
                type="radio"
                value="7"
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '7'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">8</span>
              <input
                type="radio"
                value="8"
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '8'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">9</span>
              <input
                type="radio"
                value="9"
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '9'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
            <label className="radio__container radio__container--top-align">
              <span className="radio__label">10</span>
              <input
                type="radio"
                value="10"
                name="9"
                checked={applicationForm.formControls.questions[9].answer === '10'}
                onChange={changeHandler}
              />
              <span className="checkmark checkmark--relative" />
            </label>
          </div>
          {!applicationForm.formControls.questions[9].validation.valid 
            ? (
              <div className="error">
                <p className="error-message">
                  <img className="error-icon" src={ErrorIcon} alt="error" />
                  {applicationForm.formControls.questions[9].validation.errorMessage}
                </p>
              </div>
            ) 
            : null}
        </div>

        <div className="form-group">
          <label className="label" htmlFor="legend">Are you ready to invest in your self, and achieve your goals?</label>
          <div className="radio-group">
            <label className="radio__container">
              <input
                type="radio"
                value="HELL YES! GIVE ME ALL YOU' VE GOT!"
                name="10"
                checked={applicationForm.formControls.questions[10].answer === 'HELL YES! GIVE ME ALL YOU\' VE GOT!'}
                onChange={changeHandler}
              />
              <span className="checkmark" />
              HELL YES! GIVE ME ALL YOU&apos; VE GOT!
            </label>
            <label className="radio__container">
              <input
                type="radio"
                value="No, I want to stay like this forever."
                name="10"
                checked={applicationForm.formControls.questions[10].answer === 'No, I want to stay like this forever.'}
                onChange={changeHandler}
              />
              <span className="checkmark" />
              No, I want to stay like this forever.
            </label>
          </div>
          {!applicationForm.formControls.questions[10].validation.valid 
            ? (
              <div className="error">
                <p className="error-message">
                  <img className="error-icon" src={ErrorIcon} alt="error" />
                  {applicationForm.formControls.questions[10].validation.errorMessage}
                </p>
              </div>
            ) 
            : null}
        </div>
        
        <button className="btn btn--black" type="submit">
          <span className="btn__font">Submit</span>
        </button>
      </form>
    </div>
  );
};

export default ApplicationForm;
