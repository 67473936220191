import axios from 'axios';

export const sendEmail = (email) => axios.post('https://homefithome.ch/api/customer-message', email);

export const sendApplication = (application) => axios.post('https://homefithome.ch/api/application', application);

// export const sendEmail = (email) => axios.post('http://localhost:8080/api/customer-message', email);

// export const sendApplication = (application) => axios.post('http://localhost:8080/api/application', application);

export default { sendEmail, sendApplication };
