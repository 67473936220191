import React, { useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
// import { animateScroll as scroll, Link as LinkScroll } from 'react-scroll';
import { Link as LinkRouter } from 'react-router-dom';
import Logo from '../../images/Logo-02.png';
import Aux from '../../hoc/Auxiliary';

const NavBar = ({ showSidebar }) => {
  // const [scrollNav, setScrollNav] = useState(false);

  // const changeNav = () => {
  //   if (window.scrollY >= 80) {
  //     setScrollNav(true);
  //   } else {
  //     setScrollNav(false);
  //   }
  // };

  useEffect(() => {
    // window.addEventListener('scroll', changeNav);
  }, []);

  // const toggleHome = () => {
  //   scroll.scrollToTop();
  // };

  return (
    <Aux>
      {/* <nav className={scrollNav ? 'navbar' : 'navbar navbar--active'}> */}
      <nav className="navbar">
        <div className="navbar__container">
          <LinkRouter
            className="navbar__logo"
            to="/"
            // onClick={toggleHome}
          >
            <img src={Logo} alt="logo" />
          </LinkRouter>
          <div className="navbar__mobile-icon" onClick={showSidebar}>
            <FaBars />
          </div>
          <ul className="navbar__menu">
            <li className="navbar__item">
              <LinkRouter
                className="navbar__link"
                to="/about"
              >
                About
              </LinkRouter>
            </li>
            <li className="navbar__item">
              <LinkRouter
                className="navbar__link"
                to="/application"
              >
                Application
              </LinkRouter>
            </li>
            <li className="navbar__item">
              <LinkRouter
                className="navbar__link"
                to="/contact"
              >
                Contact
              </LinkRouter>
            </li>
          </ul>
        </div>
      </nav>
    </Aux>
  );
};

export default NavBar;
