import React from 'react';
import HeroSection from '../components/HomeComponents/HeroSection';
// import AboutSection from '../components/HomeComponents/AboutSection';

const Home = () => {
  return (
    <main className="home">
      <HeroSection />
    </main>
  );
};

export default Home;
