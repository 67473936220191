import React from 'react';
import ProfileImage from '../../images/profile.jpg';
import BackgroundImage from '../../images/back1.png';
import Certification from '../../images/certified_trainer.png';

const HeroSection = () => (
  <section className="hero-section">
    <div className="hero-section__background">
      <img className="hero-section__background-image" src={BackgroundImage} alt="background" />
    </div>
    <div className="profile">
      <h1 className="profile__name">Nikolas Nomikos</h1>
      <img className="profile__profile-image" src={ProfileImage} alt="profile" />
      <p className="profile__characteristics">
        The Personal Training and Fitness Expert in Winterthur.
      </p>
    </div>
    <div className="hero-section__personal-training-certificate">
      <img className="hero-section__certification-image" src={Certification} alt="personal training certification" />
    </div>
  </section>
);

export default HeroSection;
