import React from 'react';
import ContactBoard from '../components/ContactComponents/ContactBoard';

const Contact = () => {
  return (
    <main className="contact">
      <h1 className="heading-primary heading-primary--black">CONTACT</h1>
      <ContactBoard />
    </main>
  );
};

export default Contact;
