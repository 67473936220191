import React from 'react';
import { Link } from 'react-router-dom';

const Fouter = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__links">
          <h1 className="footer__link-title">Site Map</h1>
          <Link
            to="/"
            className="footer__link"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="footer__link"
          >
            About
          </Link>
          <Link
            to="/application"
            className="footer__link"
          >
            Application
          </Link>
          <Link
            to="/contact"
            className="footer__link"
          >
            Contact
          </Link>
        </div>
        <p className="footer__website-rights">
          {' '}
          ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          Nicholas Paterakis
        </p>
      </div>
    </footer>
  );
};

export default Fouter;
