import React from 'react';
import './sass/main.scss';
import { Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import Home from './pages/Home';
import About from './pages/About';
import Application from './pages/Application';
import Contact from './pages/Contact';

function App() {
  const routes = (
    <Switch>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/application">
        <Application />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );

  return (
    <div className="App">
      <Layout>
        {routes}
      </Layout>
    </div>
  );
}

export default App;
