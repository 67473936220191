import { React, useState } from 'react';
import Aux from '../../hoc/Auxiliary';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const index = () => {
  const [sidebar, setStidebar] = useState(false);

  const showSidebar = () => setStidebar(!sidebar);

  return (
    <Aux>
      <Navbar showSidebar={showSidebar} />
      <Sidebar sidebar={sidebar} showSidebar={showSidebar} />
    </Aux>
  );
};

export default index;
