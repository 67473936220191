import { React } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar = ({ showSidebar, sidebar }) => (
  <div className={sidebar ? 'sidebar sidebar--active' : 'sidebar'} onClick={showSidebar}>
    <div className="sidebar__icon-container" onClick={showSidebar}>
      <FaTimes className="sidebar__close-icon" />
    </div>
    <div className="sidebar__container">
      <ul className="sidebar__menu">
        <Link
          className="sidebar__link"
          onClick={showSidebar}
          to="/about"
        >
          About
        </Link>
        <Link
          className="sidebar__link"
          onClick={showSidebar}
          to="/application"
        >
          Application
        </Link>
        <Link
          className="sidebar__link"
          onClick={showSidebar}
          to="/contact"
        >
          Contact
        </Link>
      </ul>
    </div>
  </div>
);

export default Sidebar;
