import React from 'react';

const About = () => (
  <main className="about">
    <h1 className="heading-primary heading-primary--black">ABOUT ME</h1>
    <div className="personal-statement">
      <p>
        I am a certified Personal Trainer Level 4, and a certified Online Trainer,
        with wide experience in sports and fitness, spanning from my childhood 
        through now. Having participated in more than 1.000 races, an ex 
        road cycling professional and marathon runner, an ex professional basketball 
        player, a black belt in Tang Soo Do, I have spent a lifetime in the
        quest for optimal physical, mental and emotional performance. 
      </p>

      <p>
        I have the skills, experience, passion and patience to help you to become 
        your Best Self. I love to help people transform their lives by reaching 
        their goals to become fitter and healthier. Whether your goal is fat loss, 
        strength or endurance, I have you covered! I teach you how to use your
        body in daily routines, how to sit and move correctly, help you learn and 
        explore the capabilities of your own body. If you are a busy person and 
        lead a sedentary lifestyle, I can help you reverse and correct the 
        negative effects of it.
      </p>

      <p>
        The programs are individually and meticulously tailored for your needs and 
        goals for maximum results, and fitted in your schedule. I constantly fine 
        tuning them to adapt as you become fitter. 
      </p>

      <p>
        I love to excel and always aim to providing the highest quality of services 
        to my clients, by continuously educating myself through seminars, courses and 
        workshops. After all, learning is a lifelong process. 
      </p>

      <p>
        Doing daily workouts, cycling or running, I keep himself connected to my passion 
        and I lead by example. Whatever your level of fitness, whatever your goal, I have 
        the knowledge and the passion to lead you there.
      </p>

      <p> 
        To your health,
        <br />
        Nikolas 
      </p>

    </div>
  </main>
);

export default About;
