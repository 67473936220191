import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Aux from '../hoc/Auxiliary';

const Layout = (props) => (
  <Aux>
    <Header />
    {props.children}
    <Footer />
  </Aux>
);

export default Layout;
