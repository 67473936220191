import { React, useState } from 'react';
import ApplicationForm from '../components/ApplicationComponents/ApplicationForm';
import Modal from '../components/UI/Modal';
import Spinner from '../components/UI/Spinner';

const Application = () => {
  const [modal, setModal] = useState({
    show: false,
    valid: false,
  });

  const [load, setLoad] = useState(false);

  const loadHandler = (value) => {
    setLoad(value);
  };

  const modalHandler = (valid = false) => {
    setModal({
      show: !modal.show,
      valid,
    });
  };
  
  return (
    <main className="application">
      <div className="application__background" />
      <Modal show={modal.show} valid={modal.valid} modalClosed={modalHandler} />
      {load ? <Spinner /> : null }
      <div className="application__title">
        <h1 className="heading-primary heading-primary--black">APPLICATION</h1>
      </div>
      <ApplicationForm modalHandler={modalHandler} loadHandler={loadHandler} />
    </main>
  );
};

export default Application;
